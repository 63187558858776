<template>
    <div>
        <!-- <div class="sec-head" v-if="!is_lesson">
            <ul class="inner">
                <li :class="item.id == sel_type[0]?'active':''" v-for="(item,index) in list" :key="index">
                    <router-link :to="{name:'list',query:{screen:item.id}}">{{item.name}}</router-link>
                </li>
            </ul>
        </div> -->
        <div class="bread_crumb w1703">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'list',query:{classcode:item.key} }"
                    v-for="(item,index) in list_info.crumbs" :key="index">{{item.value}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="common_type">
            <div class="content w1703" v-if="false">
                <div class="row">
                    <div class="type-title">一级分类：</div>
                    <ul>
                        <li>
                            <a href="">全部</a>
                        </li>
                        <li class="active">
                            <a href="">沙发</a>
                        </li>
                        <li>
                            <a href="">椅凳</a>
                        </li>
                        <li>
                            <a href="">床具</a>
                        </li>
                        <li>
                            <a href="">桌几</a>
                        </li>
                        <li>
                            <a href="">灯具</a>
                        </li>
                        <li>
                            <a href="">电器</a>
                        </li>
                        <li>
                            <a href="">柜子/架子</a>
                        </li>
                        <li>
                            <a href="">陈设饰品</a>
                        </li>
                        <li>
                            <a href="">植物花草</a>
                        </li>
                        <li>
                            <a href="">厨卫用品</a>
                        </li>
                        <li>
                            <a href="">构件五金</a>
                        </li>
                        <li>
                            <a href="">器材设备</a>
                        </li>
                        <li>
                            <a href="">材质预设</a>
                        </li>
                        <li>
                            <a href="">汽车交通</a>
                        </li>
                        <li>
                            <a href="">人物动物</a>
                        </li>
                        <li>
                            <a href="">家装空间</a>
                        </li>
                        <li>
                            <a href="">酒店空间</a>
                        </li>
                        <li>
                            <a href="">餐饮空间</a>
                        </li>
                        <li>
                            <a href="">娱乐空间</a>
                        </li>
                        <li>
                            <a href="">办公机构</a>
                        </li>
                        <li>
                            <a href="">商业空间</a>
                        </li>
                        <li>
                            <a href="">文体医疗</a>
                        </li>
                        <li>
                            <a href="">户外建筑</a>
                        </li>
                        <li>
                            <a href="">住宅</a>
                        </li>
                        <li>
                            <a href="">商业</a>
                        </li>
                        <li>
                            <a href="">公共</a>
                        </li>
                        <li>
                            <a href="">工农建筑</a>
                        </li>
                        <li>
                            <a href="">景观小品</a>
                        </li>
                        <li>
                            <a href="">景观建筑</a>
                        </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="type-title">二级分类：</div>
                    <ul>
                        <li>
                            <a href="">全部</a>
                        </li>
                        <li class="active">
                            <a href="">沙发茶几组合</a>
                        </li>
                        <li>
                            <a href="">多人沙发</a>
                        </li>
                        <li>
                            <a href="">双人沙发</a>
                        </li>
                        <li>
                            <a href="">单人沙发</a>
                        </li>
                        <li>
                            <a href="">异形沙发</a>
                        </li>
                        <li>
                            <a href="">户外沙发</a>
                        </li>
                        <li>
                            <a href="">懒人沙发</a>
                        </li>
                        <li>
                            <a href="">贵妃椅</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="common_fill w1703">
            <div class="filter-box" v-if="!is_lesson">
                <div class="filter_main filler_1">
                    <i class="iconfont icon-shaixuan"></i>
                    <span>筛选</span>
                    <ul @click="selMeal">
                        <li>
                            <div :class="meal==''?'active':''" data-meal='all'>全部</div>
                        </li>
                        <li>
                            <div :class="meal=='1'?'active':''" data-meal='1'>收费</div>
                        </li>
                        <li>
                            <div :class="meal=='2'?'active':''" data-meal='2'>VIP</div>
                        </li>
                        <li>
                            <div :class="meal=='3'?'active':''" data-meal='3'>免费</div>
                        </li>
                    </ul>
                </div>
                <div class="filter_main filler_2">
                    <i class="iconfont icon-paixu"></i>
                    <span>排序</span>
                    <ul @click="selMeal2">
                        <li>
                            <div :class="order==''?'active':''" data-order="all">全部</div>
                        </li>
                        <li>
                            <div :class="order=='price'?'active':''" data-order="price">综合</div>
                        </li>
                        <li>
                            <div :class="order=='create_at'?'active':''" data-order="create_at">最新</div>
                        </li>
                        <li>
                            <div :class="order=='hits'?'active':''" data-order="hits">热门</div>
                        </li>
                    </ul>
                </div>
                <!-- <div class="filter_main filler_3">
                    <i></i>
                    <span>渲染器</span>
                    <ul>
                        <li>
                            <a href="" class="active">全部</a>
                        </li>
                        <li>
                            <a href="">VR</a>
                        </li>
                        <li>
                            <a href="">ENS</a>
                        </li>
                        <li>
                            <a href="">LU</a>
                        </li>
                    </ul>
                </div> -->
            </div>
            <div class="filter-box" v-if="false">
                <div class="filter_main filler_1">
                    <i></i>
                    <span>筛选</span>
                    <ul>
                        <li>
                            <a href="" class="active">全部</a>
                        </li>
                        <li>
                            <a href="">付费</a>
                        </li>
                        <li>
                            <a href="">免费</a>
                        </li>
                    </ul>
                </div>
                <div class="filter_main filler_2">
                    <i></i>
                    <span>排序</span>
                    <ul>
                        <li>
                            <a href="" class="active">全部</a>
                        </li>
                        <li>
                            <a href="">最新</a>
                        </li>
                        <li>
                            <a href="">热门</a>
                        </li>

                    </ul>
                </div>
                <div class="filter_main filler_3">
                    <i></i>
                    <span>类型</span>
                    <ul>
                        <li>
                            <a href="" class="active">全部</a>
                        </li>
                        <li>
                            <a href="">系列课</a>
                        </li>
                        <li>
                            <a href="">单课</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="main w1703" v-if="Object.keys(list_info).length>0">
            <div v-if="!is_lesson" v-loading="loading">
                <ul v-if="list_info.list.length>0">
                    <li v-for="(item,index) in list_info.list" :key="index">
                        <router-link target="_blank" :to="{name:'listDetail',query:{id:item.id}}" class="detail_img">
                            <!-- <img :src="item.logo" :alt="item.title"> -->
                            <el-image :src="item.logo" lazy></el-image>
                        </router-link>
                        <div class="detail_dec">
                            <p class="detail_dec_info">
                                <router-link target="_blank" :to="{name:'listDetail',query:{id:item.id}}">{{item.title}}
                                </router-link>
                                <!-- <span>ID:{{item.id}}</span> -->
                            </p>
                            <!-- <div class="detail_dec_icon">
                            <div class="detail_add">
                                <span>收藏</span>
                                <span>下载</span>
                            </div>
                        </div> -->
                        </div>
                    </li>
                </ul>
                <el-empty v-else description="暂无数据"></el-empty>
            </div>
            <div v-if="is_lesson" v-loading="loading">
                <ul v-if="list_info.list.length>0">
                    <li @click="Link('lessonInfo', {id:item.id}, false, true)" class="floor-item"
                        v-for="(item,index) in list_info.list" :key="index">
                        <div class="listImg">
                            <img :src="item.logo" class="imgCon" alt="">
                            <!-- <img src="https://cdn.code.znzmo.com/sucai/img/images/vipFree.png" class="imgR" alt=""
                                v-if="item.isVip">
                            <div class="time">{{item.long}}</div> -->
                        </div>
                        <div class="listInfo">
                            <div class="listInfoTitle">{{item.title}}</div>
                        </div>
                    </li>
                </ul>
                <el-empty v-else description="暂无数据"></el-empty>
            </div>
        </div>
        <div class="w1703">
            <div class="pagination">
                <el-pagination background :prev-text="'上一页'" :hide-on-single-page="true" :next-text="'下一页'"
                    layout="prev, pager, next" :page-size="list_info.pageSize" :current-page="page"
                    :total="list_info.count" @current-change="changePage">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getMainCategoryList,
        getMeatListByClassCode,
        getVideoListByClassCode
    } from '@/api/index.js'
    export default {
        data() {
            return {
                list: [],
                sel_type: [],
                one_list: [],
                is_lesson: false,
                loading: true,
                title: '',
                list_info: {},
                meal: '',
                order: '',
                page: 1,
            }
        },
        created() {
            this.is_lesson = this.$route.query.jump_type == 2 ? true : false;
            this.getList();
        },
        methods: {
            changePage(num) {
                this.page = num;
                this.getList();
            },
            getUrlData() {
                let url = this.$route.query.screen + '';
                if (url.indexOf('-') > -1) {
                    url = url.split('-');
                    this.sel_type = url;
                    // console.log(url);
                } else {
                    this.sel_type[0] = url;
                    // console.log(url);
                }
                this.$forceUpdate();
            },
            getList() {
                this.loading = true;
                if (this.is_lesson) {
                    getVideoListByClassCode({
                        classcode: this.$route.query.classcode,
                        page: this.page,
                    }).then(r => {
                        this.list_info = r;
                        this.loading = false;
                    })
                } else {
                    getMeatListByClassCode({
                        classcode: this.$route.query.classcode,
                        keyword: this.$route.query.keyword,
                        meal: this.meal,
                        order: this.order,
                        page: this.page,
                    }).then(r => {
                        this.list_info = r;
                        this.loading = false;
                    })
                }
            },
            selMeal(event) {
                let el = event.target;
                let {
                    meal,
                } = el.dataset;
                if (!meal) return false;
                if (meal == 'all') {
                    this.meal = '';
                } else {
                    this.meal = meal;
                }
                this.getList();
            },
            selMeal2(event) {
                let el = event.target;
                let {
                    order,
                } = el.dataset;
                if (!order) return false;
                if (order == 'all') {
                    this.order = '';
                } else {
                    this.order = order;
                }
                this.getList();
            }
        },
        mounted() {
            getMainCategoryList().then(res => {
                this.list = res;
                this.$forceUpdate();
                res.forEach((item, index) => {
                    if (item.id == this.sel_type[0]) {
                        this.title = item.name
                    }
                })


                // res.forEach((item, index) => {
                //     if (item.hasChild && item.id == this.sel_type[0]) {
                //         getMainCategoryList({
                //             cate_id: item.id
                //         }).then(r => {
                //             this.one_list = r;
                //             console.log(this.one_list)
                //             this.$forceUpdate();
                //             r.forEach((item2, index2) => {
                //                 if (item2.hasChild) {
                //                     getMainCategoryList({
                //                         cate_id: item2.id
                //                     }).then(rs => {
                //                         this.one_list[index2]
                //                             .child = rs;
                //                         this.$forceUpdate();
                //                         rs.forEach((item3, index3) => {
                //                             if (item3.hasChild) {
                //                                 getMainCategoryList
                //                                     ({
                //                                         cate_id: item3
                //                                             .id
                //                                     }).then(rss => {
                //                                         this.one_list[
                //                                                 index2
                //                                             ]
                //                                             .child[
                //                                                 index3
                //                                             ]
                //                                             .child =
                //                                             rss;
                //                                         this
                //                                             .$forceUpdate();
                //                                     })
                //                             }
                //                         })
                //                     })
                //                 }
                //             })
                //         })
                //     }
                // })
                // this.list = list;
                // this.$forceUpdate();

            })
        },
        watch: {
            '$route'() {
                this.is_lesson = this.$route.query.jump_type == 2 ? true : false;
                this.page = 1;
                this.getList();
            },
        },
    };
</script>

<style lang="less" scoped>
    .sec-head {
        height: 60px;
        font-size: 16px;

        .inner {
            position: relative;
            display: flex;
            align-items: center;
            width: 1800px;
            margin: 0 auto;
            height: 60px;
            font-size: 16px;

            li {
                display: flex;
                justify-content: center;
                height: 100%;
                width: 100px;
                margin-right: 14px;
                line-height: 60px;

                a {
                    position: relative;
                    line-height: 60px;
                    display: block;
                    height: 100%;
                    text-align: center;
                    color: #333;
                }
            }

            .active {
                color: #e80000;
                background-color: #f6f6f6;
                border-bottom: 2px solid #e80000;
            }
        }
    }

    .bread_crumb {
        display: flex;
        align-items: center;
        height: 58px;
        color: #bbb;

        p {
            color: #bbb;
            font-size: 14px;

            a {
                color: inherit;
            }
        }
    }

    .common_type {
        margin-bottom: 20px;

        .content {
            border-bottom: 1px solid #eee;

            .row {
                display: flex;

                .type-title {
                    font-size: 14px;
                    font-weight: 600;
                    color: #333;
                    padding-top: 10px;
                }

                ul {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-bottom: 20px;
                    flex: 1;

                    li {
                        position: relative;
                        padding: 10px 11px 4px;
                        font-size: 14px;

                        a {
                            display: inline-block;
                            color: #666;
                            padding-bottom: 6px;
                            line-height: 1;
                            font-size: 14px;
                        }
                    }

                    .active {
                        a {
                            color: #e80000;
                            border-bottom: 1px solid #e80000;
                        }
                    }
                }
            }
        }
    }

    .main {
        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                position: relative;
                height: 100%;
                width: 340px;
                display: flex;
                flex-direction: column;
                margin: 0 25px 25px 0;
                border-radius: 5px;
                transition: all .3s linear;
                background-color: #fff;

                &:hover {
                    box-shadow: 0 8px 15px 0 rgb(0 0 0 / 15%);
                    transform: translateY(-2px);
                }

                .detail_img {
                    position: relative;
                    width: 100%;
                    height: 340px;
                    text-align: center;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;

                    .el-image {
                        width: 100%;
                        height: 100%;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;

                        img {
                            border-top-left-radius: 5px;
                            border-top-right-radius: 5px;
                            transition: opacity .3s linear;
                            // opacity: .3;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        /deep/ .el-image__inner {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    img {
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        transition: opacity .3s linear;
                        // opacity: .3;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .detail_dec {
                    // height: 80px;
                    background-color: #fff;
                    padding: 15px 14px 0;
                    line-height: 1;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;

                    .detail_dec_info {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 28px;
                        // border-bottom: 1px solid #eee;
                        padding-bottom: 12px;

                        a {
                            color: #666;
                            width: 100%;
                            font-size: 14px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        span {
                            color: #666;
                            font-size: 14px;
                        }
                    }

                    .detail_dec_icon {
                        display: flex;
                        font-size: 12px;
                        height: 40px;
                        color: #bbb;

                        .detail_add {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            span {
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            li:nth-child(5n) {
                margin-right: 0;
            }

            .year-member-subscript {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }

            .floor-item {
                position: relative;
                width: 344px;
                margin-right: 20px;
                margin-bottom: 20px;
                float: left;
                border-radius: 8px;
                overflow: hidden;

                .listImg {
                    cursor: pointer;
                    width: 100%;
                    height: 210px;
                    border-radius: 8px 8px 0 0;
                    overflow: hidden;
                    position: relative;

                    .imgCon {
                        width: 100%;
                        height: 100%;
                        -webkit-transition: all 1s;
                        transition: all 1s;

                        &:hover {
                            transform: scale(1.1);
                        }
                    }

                    .imgR {
                        width: 82px;
                        height: 28px;
                        position: absolute;
                        right: 0px;
                        top: 0px;
                        transform: scale(1);
                    }

                    .time {
                        position: absolute;
                        right: 10px;
                        bottom: 10px;
                        padding: 0 8px;
                        height: 22px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #fff;
                        line-height: 22px;
                        background: rgba(0, 0, 0, 0.6);
                        border-radius: 11px;
                    }
                }

                .listInfo {
                    background: #fff;
                    padding: 20px;
                    cursor: pointer;

                    .listInfoTitle {
                        cursor: pointer;
                        height: 25px;
                        line-height: 25px;
                        font-size: 18px;
                        font-weight: 600;
                        color: #333;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        -webkit-transition: all 0.3s;
                        transition: all 0.3s;
                        display: inline-block;
                        width: 100%;
                    }

                    .keInfo {
                        margin-top: 16px;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: justify;
                        -moz-box-pack: justify;
                        -ms-flex-pack: justify;
                        justify-content: space-between;
                        -webkit-box-align: center;
                        -moz-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        height: 20px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #999;
                        line-height: 20px;

                        .view {
                            display: flex;
                            align-items: center;

                            img {
                                margin-right: 4px;
                            }
                        }
                    }
                }
            }

            .floor-item:nth-child(5n) {
                margin-right: 0;
            }
        }
    }

    .common_fill {
        margin-bottom: 20px;
        height: 46px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;

        .filter-box {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            height: 46px;
            position: relative;

            .filter_main {
                position: relative;
                display: flex;
                align-items: center;
                padding: 0 44px 0 66px;

                i {
                    position: absolute;
                    left: 0;
                    top: 2px;
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                }

                span {
                    color: #333;
                    font-weight: 600;
                }

                ul {
                    display: flex;

                    li {
                        margin-left: 24px;
                        cursor: pointer;

                        a {
                            color: #666;
                            height: 30px;
                            line-height: 30px;
                        }

                        .active {
                            color: #e80000;
                            border-bottom: 1px solid #e80000;
                        }
                    }
                }
            }

            .filler_1 {
                padding-left: 22px;

                i {
                    background-position: -358px -48px;
                }
            }

            .filler_2 {
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 20px;
                    background-color: #ddd;
                }

                i {
                    position: absolute;
                    left: 44px;
                    top: 2px;
                    background-position: -381px -48px;
                }
            }

            .filler_3 {
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 20px;
                    background-color: #ddd;
                }

                i {
                    position: absolute;
                    left: 44px;
                    top: 8px;
                    background-position: -402px -47px;
                }
            }
        }
    }

    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 60px;

        li {
            height: 40px;
            width: 40px;
            line-height: 40px;
        }

        .pagination-item {
            min-width: 32px;
            text-align: center;
            list-style: none;
            background-color: #fff;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            outline: 0;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            border: none;
            width: 40px;
            height: 40px;
            background: #fafafa;
            border-radius: 8px;
            margin: 0 6px;

            a {
                color: #333;
            }
        }

        .pagination-item-active {
            font-weight: 400;
            font-size: 14px;
            background: #333;

            a {
                color: #fff;
            }
        }

        .pagination-prev {
            width: 78px;
            height: 40px;
            background: #fafafa;
            border-radius: 8px;

            a {
                color: #333;
            }

            span:nth-child(1) {
                display: inline-block;
                background: url(https://cdn.code.znzmo.com/sucai/img/images/pagepreIcon.png) no-repeat center;
                background-size: cover;
                width: 14px;
                height: 15px;
                vertical-align: middle;
            }
        }

        .pagination-next {
            width: 78px;
            height: 40px;
            background: #fafafa;
            border-radius: 8px;

            a {
                color: #333;
            }

            span:nth-child(2) {
                display: inline-block;
                background: url(https://cdn.code.znzmo.com/sucai/img/images/pagenextIcon.png) no-repeat center;
                background-size: cover;
                width: 14px;
                height: 15px;
                vertical-align: middle;
            }
        }
    }

    @media only screen and (max-width: 1800px) {
        .sec-head .inner {
            width: 1500px;
        }

        .main ul li {
            width: 280px;

            .detail_img {
                height: 280px;
            }
        }

        .main ul .floor-item {
            width: 280px;

            .listImg {
                height: 160px;
            }
        }
    }

    @media only screen and (max-width: 1500px) {
        .sec-head .inner {
            width: 1200px;
        }

        .main ul li {
            width: 220px;

            .detail_img {
                height: 220px;
            }

            .detail_dec {
                padding: 10px 10px 0;
                // height: 70px;

                .detail_dec_info {
                    a {
                        font-size: 12px;
                    }

                    span {
                        font-size: 12px;
                    }
                }

                .detail_dec_icon {
                    height: 30px;
                }
            }
        }

        .main ul .floor-item {
            width: 224px;

            .listImg {
                height: 155px;
            }

            .listInfo {
                padding: 16px;

                .listInfoTitle {
                    font-size: 14px;
                }
            }
        }
    }

    // @media only screen and (max-width: 1000px) {
    //     .sec-head {
    //         height: auto;

    //         .inner {
    //             height: auto;
    //             width: 100%;
    //             flex-wrap: wrap;

    //             li {
    //                 width: 30%;
    //                 margin-right: 3%;
    //             }
    //         }
    //     }

    //     .common_type .content .row {
    //         flex-wrap: wrap;

    //         ul {
    //             width: 100%;
    //             flex: none;
    //         }

    //         .type-title {
    //             margin-bottom: 20px;
    //         }
    //     }

    //     .common_fill {
    //         height: auto;

    //         .filter-box {
    //             height: auto;
    //             flex-wrap: wrap;

    //             .filter_main {
    //                 padding-left: 22px;
    //                 margin-bottom: 10px;
    //             }

    //             .filler_2 {
    //                 i {
    //                     left: 0;
    //                 }
    //             }

    //             .filler_3 {
    //                 i {
    //                     left: 0;
    //                 }
    //             }

    //             .filler_2::before {
    //                 display: none;
    //             }

    //             .filler_3::before {
    //                 display: none;
    //             }
    //         }

    //     }

    //     .main ul {
    //         li {
    //             width: calc(50% - 5px);
    //             margin-right: 10px;

    //             .detail_img {
    //                 height: auto;
    //             }
    //         }

    //         li:nth-child(5n) {
    //             margin-right: 10px;
    //         }

    //         li:nth-child(2n) {
    //             margin-right: 0;
    //         }

    //         .floor-item {
    //             width: calc(50% - 5px);
    //             margin-right: 10px;
    //             border-radius: 4px;

    //             .listImg {
    //                 height: auto;
    //                 border-radius: 0;
    //             }

    //             .listInfo {
    //                 padding: 8px;

    //                 .keInfo {
    //                     margin-top: 8px;
    //                 }
    //             }
    //         }

    //         .floor-item:nth-child(5n) {
    //             margin-right: 10px;
    //         }

    //         .floor-item:nth-child(2n) {
    //             margin-right: 0;
    //         }

    //     }
    // }
</style>